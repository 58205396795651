<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-table-currencies />
  </div>
</template>

<script>
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import BTableCurrencies from './BTableCurrencies.vue'

export default {
  components: {
    BTableCurrencies,
    Breadcrumbs,
  },
  data() {
    return {
      pageTitle: this.$t('Exchange rates'),
      breadcrumbItems: [],
    }
  },
  created() {
    this.setBreadcrumbs()
  },
  methods: {
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.pageTitle,
          to: '/currencies/',
          active: true,
        },
      ]
    },
  },
}
</script>
