<template>
  <div
    v-if="currencies.length === 0"
    class="d-flex justify-content-center mb-1"
  >
    <b-spinner label="Loading..." />
  </div>
  <div v-else>
    <b-row>
      <b-col>
        <b-card>
          <div class="head-card">
            <div class="head-card-top">
              <h4>{{ $t('page.currency.title') }}</h4>
              <feather-icon
                v-b-tooltip.hover.top="$t('Close')"
                icon="XIcon"
                size="18"
                class="toggle-close cursor-pointer"
                @click="$router.go(-1)"
              />
            </div>
            <hr>
          </div>
          <div class="custom-search d-flex justify-content-between">
            <div class="total">
              <strong>{{ $t('page.currency.lastupdate') }}: {{ getFormattedDate(new Date(last_update_date), true) }}</strong>
            </div>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('form.search') }}</label>
              <b-input-group>
                <b-form-input
                  ref="searchInput"
                  :value="filter"
                  :placeholder=" $t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="filter = $event.target.value"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="filter = $refs.searchInput.vModelValue"
                  >
                    <feather-icon
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <b-table
            responsive
            striped
            hover
            :items="currencies"
            :fields="fields"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #head(name)>
              <div class="text-nowrap">
                {{ $t('page.currency.currency') }}
              </div>
            </template>

            <template #head(symbol)>
              <div class="text-nowrap">
                {{ $t('page.currency.symbol') }}
              </div>
            </template>

            <template #head(rate)>
              <div class="text-nowrap">
                {{ $t('page.currency.rate') }}/1$
              </div>
            </template>

            <template #cell(symbol)="data">
              {{ getSymbol(data.item.name) }}
            </template>

            <template #cell(rate)="data">
              {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 3 }).format(data.value) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow,
  BCol,
  BCard,
  BSpinner,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import getFormattedDate from '@/utils/date-formatter'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currencies: [],
      last_update_date: '',
      getFormattedDate,
      pageLength: 20,
      dir: false,
      fields: [
        { key: 'name', label: 'Name' },
        {
          key: 'symbol',
          label: 'Symbol',
          thStyle: { width: '10%', textAlign: 'center' },
          tdClass: 'text-center',
        },
        { key: 'rate', label: 'Rate', thStyle: { width: '10%' } },
      ],
      filter: null,
      filterOn: [],
      sortDesc: true,
      sortDirection: 'asc',
      currency_symbols: [
        { AFN: 'Af ' },
        { AED: 'Dhs. ' },
        { ALL: 'Lek ' },
        { AMD: 'դ ' },
        { ANG: 'ƒ ' },
        { AOA: 'Kz ' },
        { ARS: '$ ' },
        { AUD: '$ ' },
        { AWG: 'ƒ ' },
        { AZN: '₼ ' },
        { BAM: 'KM ' },
        { BBD: '$ ' },
        { BDT: '৳ ' },
        { BGN: 'лв ' },
        { BHD: '.د.ب ' },
        { BIF: 'FBu ' },
        { BMD: '$ ' },
        { BND: '$ ' },
        { BOB: '$b ' },
        { BRL: 'R$ ' },
        { BSD: '$ ' },
        { BTN: 'Nu. ' },
        { BWP: 'P ' },
        { BYR: 'p. ' },
        { BYN: 'Br. ' },
        { BZD: 'BZ$ ' },
        { CAD: '$ ' },
        { CDF: 'FC ' },
        { CHF: 'CHF ' },
        { CLF: 'UF ' },
        { CLP: '$ ' },
        { CNY: '¥ ' },
        { COP: '$ ' },
        { CRC: '₡ ' },
        { CUP: '⃌ ' },
        { CVE: '$ ' },
        { CZK: 'Kč ' },
        { DJF: 'Fdj ' },
        { DKK: 'kr ' },
        { DOP: 'RD$ ' },
        { DZD: 'دج ' },
        { EGP: 'E£ ' },
        { ETB: 'Br ' },
        { EUR: '€ ' },
        { ERN: ' ناكفا' },
        { FJD: '$ ' },
        { FOK: 'kr ' },
        { FKP: '£ ' },
        { GBP: '£ ' },
        { GEL: 'ლ ' },
        { GHS: '¢ ' },
        { GIP: '£ ' },
        { GMD: 'D ' },
        { GNF: 'FG ' },
        { GTQ: 'Q ' },
        { GYD: '$ ' },
        { GGP: '£ ' },
        { HKD: '$ ' },
        { HNL: 'L ' },
        { HRK: 'kn ' },
        { HTG: 'G ' },
        { HUF: 'Ft ' },
        { IDR: 'Rp ' },
        { ILS: '₪ ' },
        { INR: '₹ ' },
        { IQD: 'ع.د ' },
        { IRR: '﷼ ' },
        { ISK: 'kr ' },
        { IMP: '£ ' },
        { JEP: '£ ' },
        { JMD: 'J$ ' },
        { JOD: 'JD ' },
        { JPY: '¥ ' },
        { KES: 'KSh ' },
        { KGS: 'лв ' },
        { KHR: '៛ ' },
        { KMF: 'CF ' },
        { KID: '$ ' },
        { KPW: '₩ ' },
        { KRW: '₩ ' },
        { KWD: 'د.ك ' },
        { KYD: '$ ' },
        { KZT: '₸ ' },
        { LAK: '₭ ' },
        { LBP: '£ ' },
        { LKR: '₨ ' },
        { LRD: '$ ' },
        { LSL: 'L ' },
        { LTL: 'Lt ' },
        { LVL: 'Ls ' },
        { LYD: 'ل.د ' },
        { MAD: 'د.م. ' },
        { MDL: 'L ' },
        { MGA: 'Ar ' },
        { MKD: 'ден ' },
        { MMK: 'K ' },
        { MNT: '₮ ' },
        { MOP: 'MOP$ ' },
        { MRO: 'UM ' },
        { MUR: '₨ ' },
        { MRU: 'UM ' },
        { MVR: '.ރ ' },
        { MWK: 'MK ' },
        { MXN: '$ ' },
        { MYR: 'RM ' },
        { MZN: 'MT ' },
        { NAD: '$ ' },
        { NGN: '₦ ' },
        { NIO: 'C$ ' },
        { NOK: 'kr ' },
        { NPR: '₨ ' },
        { NZD: '$ ' },
        { OMR: '﷼ ' },
        { PAB: 'B/. ' },
        { PEN: 'S/. ' },
        { PGK: 'K ' },
        { PHP: '₱ ' },
        { PKR: '₨ ' },
        { PLN: 'zł ' },
        { PYG: 'Gs ' },
        { QAR: '﷼ ' },
        { RON: 'lei ' },
        { RSD: 'Дин. ' },
        { RUB: '₽ ' },
        { RWF: 'ر.س ' },
        { SAR: '﷼ ' },
        { SBD: '$ ' },
        { SCR: '₨ ' },
        { SDG: '£ ' },
        { SEK: 'kr ' },
        { SGD: '$ ' },
        { SHP: '£ ' },
        { SLL: 'Le ' },
        { SOS: 'S ' },
        { SRD: '$ ' },
        { SSP: '£ ' },
        { STD: 'Db ' },
        { STN: 'Db ' },
        { SVC: '$ ' },
        { SYP: '£ ' },
        { SZL: 'L ' },
        { THB: '฿ ' },
        { TJS: 'TJS ' },
        { TMT: 'm ' },
        { TND: 'د.ت ' },
        { TOP: 'T$ ' },
        { TRY: '₤ ' },
        { TTD: '$ ' },
        { TWD: 'NT$ ' },
        { TVD: 'TV$ ' },
        { TZS: 'TSh ' },
        { UAH: '₴ ' },
        { UGX: 'USh ' },
        { USD: '$ ' },
        { UYU: '$U ' },
        { UZS: 'лв ' },
        { VEF: 'Bs ' },
        { VES: 'Bs. S ' },
        { VND: '₫ ' },
        { VUV: 'VT ' },
        { WST: 'WS$ ' },
        { XAF: 'FCFA ' },
        { XCD: '$ ' },
        { XDR: 'SDR ' },
        { XOF: 'FCFA ' },
        { XPF: 'F ' },
        { YER: '﷼ ' },
        { ZAR: 'R ' },
        { ZMK: 'ZK ' },
        { ZMW: 'ZK ' },
        { ZWL: 'Z$ ' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getCurrencies()
  },
  methods: {
    async getCurrencies() {
      const responseData = await useJwt.currencies(this.$route.params.id)
      // this.currencies = responseData.data.currencies.filter(c => c.name !== 'USD') || []
      this.currencies = responseData.data.currencies || []
      this.last_update_date = responseData.data.last_update_date || ''
    },
    getSymbol(key) {
      const idx = this.currency_symbols.findIndex(c => c[key])
      return idx !== -1 ? this.currency_symbols[idx][key] : ''
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
